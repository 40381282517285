export const common = {
  appName:                        "Frioarte Monitoring",
  version:                        "0.17.1",
  apiUsersPath:                   "users",
  apiAuthpath:                    "auth",
  apiShopsPath:                   "shops",
  apiCompaniesPath:               "companies",
  apiRolesPath:                   "roles",
  apiRegionsPath:                 "regions",
  apiInstallationTypesPath:       "installation_types",
  apiTaskDefinitionsPath:         "task_definitions",
  apiTaskOrdersPath:              "task_orders",
  apiTaskOrderShopFiltersPath:   "task_order_scope_filters",
  apiTaskOrderChangesPath:        "task_order_changes",
  apiTasksPath:                   "tasks",
  apiShopParamSourcesPath:        "shop_param_sources",
  apiChangeDeifnitionsPath:       "change_definitions"
};
